import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, TextField, SelectField } from '@agilepartners/fe-sdk-uikit';
import { ProgressBar } from 'components';
import { useHistory } from 'react-router-dom';
import useKyc from 'hooks/useKyc';

export const General = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { step, dispatch, kycData, countryList } = useKyc();

  const gendersList = useMemo(() => {
    return [
      { label: t('options.gender.male'), value: 'MALE' },
      { label: t('options.gender.female'), value: 'FEMALE' },
    ];
  }, [t]);

  const schema = useMemo(() => {
    return yup.object().shape({
      countryResidence: yup.string().required(t('validations.errors.required')),
      firstName: yup.string().required(t('validations.errors.required')),
      middleName: yup.string(),
      lastName: yup.string().required(t('validations.errors.required')),
      gender: yup.string().required(t('validations.errors.required')),
    });
  }, [t]);

  const { register, handleSubmit, formState, control, watch, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: kycData,
  });

  const formValues = watch();

  const onFormSubmit = (data: any) => {
    dispatch({ type: 'SET_DATA', data });
    push(`/step/${step?.index! + 1}`);
  };

  const goBack = () => {
    if (!formValues.countryResidence) {
      push(`/step/${step?.index! - 1}`);
      return;
    }

    setValue('countryResidence', undefined);
    return;
  };

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="shadow auth-w-full auth-p-10 lg:auth-p-24 auth-bg-white auth-rounded"
    >
      <div className="px-6 py-5 border-t-2 border-b-2 border-gray-200">
        <div className="mb-6">
          <SelectField
            disabled={!!formValues.countryResidence}
            className="mb-2"
            label={t('fields.countryResidence')}
            placeholder={t('fields.countryResidence')}
            options={countryList}
            control={control}
            error={formState.errors.countryResidence}
            {...register('countryResidence', { required: true })}
          />
          {!!formValues.countryResidence && <div className="text-xs text-gray-500">{t('helpers.onEdit')}</div>}
        </div>

        {!!formValues.countryResidence && (
          <>
            <div className="flex mb-4">
              <TextField
                label={t('fields.firstName')}
                placeholder={t('fields.firstName')}
                type="text"
                error={formState.errors.firstName}
                {...register('firstName', { required: true })}
                className="w-full mr-4"
              />

              <TextField
                label={t('fields.middleName')}
                placeholder={t('fields.middleName')}
                topHelper={t('helpers.optional')}
                type="text"
                error={formState.errors.middleName}
                {...register('middleName', { required: true })}
                className="w-full"
              />
            </div>

            <TextField
              label={t('fields.lastName')}
              placeholder={t('fields.lastName')}
              type="text"
              error={formState.errors.lastName}
              {...register('lastName', { required: true })}
              className="mb-4"
            />

            <SelectField
              label={t('fields.gender')}
              placeholder={t('fields.gender')}
              options={gendersList}
              control={control}
              error={formState.errors.gender}
              {...register('gender', { required: true })}
            />
          </>
        )}
      </div>

      <div className="flex items-center justify-between px-8 py-3 text-xs sm:px-6">
        <ProgressBar current={step?.step} max={8} />

        <div className="flex items-center">
          <Button variant="tertiary" className="mr-4" onClick={goBack}>
            {t('buttons.back')}
          </Button>
          <Button type="submit" variant="primary">
            {t('buttons.continue')}
          </Button>
        </div>
      </div>
    </form>
  );
};
