import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import { TextField, Button } from '@agilepartners/fe-sdk-uikit';
import { PasswordRule, ProgressBar } from 'components';
import { useHistory } from 'react-router-dom';
import useKyc from 'hooks/useKyc';

export const CreatePassword = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { step } = useKyc();

  const schema = useMemo(() => {
    return yup.object().shape({
      password: yup
        .string()
        .min(10, t('validations.errors.minLength', { limit: 10 }))
        .max(40, t('validations.errors.maxLength', { limit: 40 }))
        .matches(/(?=.*[A-Z])/, t('validations.errors.oneUpperCase'))
        .matches(/(?=.*[a-z])/, t('validations.errors.oneLowerCase'))
        .matches(/(?=.*\d)/, t('validations.errors.oneDigit'))
        .required(t('validations.errors.required')),
      passwordRepeat: yup
        .string()
        .min(10, t('validations.errors.minLength', { limit: 10 }))
        .max(40, t('validations.errors.maxLength', { limit: 40 }))
        .matches(/(?=.*[A-Z])/, t('validations.errors.oneUpperCase'))
        .matches(/(?=.*[a-z])/, t('validations.errors.oneLowerCase'))
        .matches(/(?=.*\d)/, t('validations.errors.oneDigit'))
        .oneOf([yup.ref('password'), null], t('validations.errors.passwordDontMatch'))
        .required(t('validations.errors.required')),
    });
  }, [t]);

  const { register, handleSubmit, formState, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const formValues = watch();

  const onFormSubmit = () => {
    push(`/step/${step?.index! + 1}`);
  };

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="shadow auth-w-full auth-p-10 lg:auth-p-24 auth-bg-white auth-rounded"
    >
      <div className="px-6 py-5 border-t-2 border-b-2 border-gray-200">
        <div className="mb-8">
          <TextField
            label={t('fields.password')}
            placeholder={t('fields.password')}
            type="password"
            error={formState.errors.password}
            {...register('password', { required: true })}
          />
        </div>
        <div className="mb-8">
          <TextField
            label={t('fields.repassword')}
            placeholder={t('fields.repassword')}
            type="password"
            error={formState.errors.passwordRepeat}
            {...register('passwordRepeat', { required: true })}
          />
        </div>
        <div className="text-xs text-gray-700">
          <div className="flex flex-wrap pt-3">
            <PasswordRule
              isValid={formValues.password && formValues.password.length >= 10 && formValues.password.length <= 40}
              ruleName={t('rules.length')}
            />
            <PasswordRule
              isValid={formValues.password && /(?=.*[A-Z])/.test(formValues.password)}
              ruleName={t('rules.oneUpperCase')}
            />
            <PasswordRule
              isValid={formValues.password && /(?=.*[a-z])/.test(formValues.password)}
              ruleName={t('rules.oneLowerCase')}
            />
            <PasswordRule
              isValid={formValues.password && /(?=.*\d)/.test(formValues.password)}
              ruleName={t('rules.oneDigit')}
              subRuleName={t('rules.oneDigitSpec')}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between px-8 py-3 text-xs sm:px-6">
        <ProgressBar current={step?.step} max={8} />
        <Button type="submit" variant="primary">
          {t('buttons.createPassword')}
        </Button>
      </div>
    </form>
  );
};
