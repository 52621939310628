import React, { FC } from 'react';
import { map } from 'lodash';
import { useLocation } from 'react-router-dom';

import { mocSteps } from '../domain/kyc/flow';

import { Step } from './Step';

export const ProgressOverview: FC = () => {
  const location = useLocation();

  const activeStep = location.pathname.split('/')[2];

  return (
    <div className="w-full max-w-xs mr-8 overflow-hidden bg-white rounded-lg shadow">
      {map(mocSteps, (s, i) => (
        <Step key={i} step={s} active={activeStep} />
      ))}
    </div>
  );
};
