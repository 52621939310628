import React, { FC } from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { ReactComponent as IconCheckmark } from 'assets/icons/icon-checkmark.svg';

import { SubStep } from './SubStep';

export interface IStep {
  id: string;
  title: string;
  parent_id?: string;
  index?: number;
  completed: boolean;
  child?: IStep[];
}

export interface StepProps {
  step: IStep;
  active: string;
}

export const Step: FC<StepProps> = (props: StepProps) => {
  const { step, active } = props;

  const isActive = step.id === active || step.child?.find((c) => c.parent_id === step.id && c.id === active);
  const isCompleted = step.completed;

  const Icon = () => {
    return (
      <div
        className={classNames(
          'mr-2 h-6 w-6 rounded-full justify-center items-center flex text-xs bg-gray-200 font-medium',
          {
            'bg-green-100': isCompleted,
            'bg-blue-10 text-blue-400': isActive,
          }
        )}
      >
        {isCompleted && !isActive ? <IconCheckmark /> : step.index}
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center px-6 py-5 font-sans border-t-2 border-gray-200 cursor-pointer">
        <Icon />
        <span className={classNames('text-gray-700 text-xs', { 'text-gray-900 font-semibold': isActive })}>
          {step.title}
        </span>
      </div>
      {isActive && map(step.child, (c, i) => <SubStep key={i} active={active} step={c} />)}
    </>
  );
};
