import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProgressOverview } from 'components';
import useKyc from 'hooks/useKyc';

import { TermsAndConditions } from './step/TermsAndConditions';
import { CreatePassword } from './step/CreatePassword';
import { General } from './step/General';
import { AddressOfResidence } from './step/AddressOfResidence';
import { ProofOfAddress } from './step/ProofOfAddress';
import { IdDocument } from './step/IdDocument';

export const KycWrapper = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();

  const { step } = useKyc();

  return (
    <div className="w-full px-16 mx-auto mb-6 max-w-8xl">
      <h1 className="py-6 text-2xl font-semibold text-gray-700 font-fira">{t('kyc.title')}</h1>
      <div className="flex items-start">
        <ProgressOverview />
        <div className="flex flex-col flex-1 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-xs font-medium text-gray-700">{step?.title}</h3>
          </div>
          <Switch>
            <Route path={match.path + '/1'} exact>
              <TermsAndConditions />
            </Route>
            <Route path={match.path + '/2'} exact>
              <CreatePassword />
            </Route>
            <Route path={match.path + '/3'} exact>
              <General />
            </Route>
            <Route path={match.path + '/4'} exact>
              <AddressOfResidence />
            </Route>
            <Route path={match.path + '/5'} exact>
              <ProofOfAddress />
            </Route>
            <Route path={match.path + '/6'} exact>
              <IdDocument />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
