import { FC } from 'react';

interface ProgressBarProps {
  current?: number;
  max: number;
}

export const ProgressBar: FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const { current = 1, max } = props;

  return (
    <div>
      <span className="font-medium text-gray-500">
        Step
        <span className="ml-1 text-gray-700">
          {current} of {max}
        </span>
      </span>
      <div style={{ width: 128 }} className="flex h-2 mt-1 overflow-hidden text-xs bg-gray-200 rounded">
        <div
          style={{
            width: (current / max) * 128,
          }}
          className="flex flex-col justify-center text-center text-white bg-blue-200 shadow-none whitespace-nowrap"
        />
      </div>
    </div>
  );
};
