import React, { ReactNode } from 'react';

import { Footer } from './Footer';
import { Topbar } from './Topbar';

export const LayoutApp = ({ children }: { children: ReactNode }) => (
  <div className="flex h-full min-h-screen bg-gray-200">
    <div className="flex flex-col w-full">
      <Topbar />
      <div className="flex-grow w-full bg-gray-200">
        <div className="flex justify-center mx-auto">{children}</div>
      </div>
      <Footer />
    </div>
  </div>
);
