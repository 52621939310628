import { useTranslation } from 'react-i18next';
import { Button, SelectField, UploadField, IconDocument } from '@agilepartners/fe-sdk-uikit';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash-es';
import useKyc from 'hooks/useKyc';
import { LabelValue, ProgressBar } from 'components';

export const ProofOfAddress = () => {
  const { t } = useTranslation();
  const { step, kycData, dispatch } = useKyc();
  const { push } = useHistory();

  const schema = useMemo(() => {
    return yup.object().shape({
      proofDocumentAddress: yup.mixed().test({
        message: t('validations.errors.required'),
        test: (arr: any) => arr.length !== 0,
      }),
      proofAddressDocumentType: yup.string().required(t('validations.errors.required')),
    });
  }, [t]);

  const { register, handleSubmit, formState, control, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: kycData,
  });

  const onFormSubmit = (data: any) => {
    dispatch({ type: 'SET_DATA', data });
    push(`/step/${step?.index! + 1}`);
  };

  const goBack = () => {
    push(`/step/${step?.index! - 1}`);
  };

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="shadow auth-w-full auth-p-10 lg:auth-p-24 auth-bg-white auth-rounded"
    >
      <div className="px-6 py-5 border-t-2 border-b-2 border-gray-200">
        <div className="mb-4">
          <div className="flex">
            <div className="flex-1">
              <LabelValue label={t('fields.countryResidence')} value={get(kycData, 'countryResidence')} />
              <LabelValue label={t('fields.suburb')} value={get(kycData, 'suburb')} />
              <LabelValue label={t('fields.street')} value={get(kycData, 'street')} />
            </div>
            <div className="flex-1">
              <LabelValue label={t('fields.city')} value={get(kycData, 'city')} />
              <LabelValue label={t('fields.country')} value={get(kycData, 'country')} />
              <LabelValue label={t('fields.streetType')} value={get(kycData, 'streetType')} />
            </div>
            <div className="flex-1">
              <LabelValue label={t('fields.postalCode')} value={get(kycData, 'postalCode')} />
              <LabelValue label={t('fields.state')} value={get(kycData, 'state')} />
              <LabelValue
                label={t('fields.buildingUnitNumber')}
                value={`${get(kycData, 'buildingNumber') || '—'} / ${get(kycData, 'unitNumber') || '—'}`}
              />
            </div>
          </div>
          <Button
            onClick={goBack}
            type="button"
            size="small"
            variant="secondary"
            className="w-full"
            childrenClass="text-sm"
          >
            {t('buttons.editAddressDetails')}
          </Button>
        </div>

        <SelectField
          label={t('fields.proofAddressDocumentType')}
          placeholder={t('fields.proofAddressDocumentType')}
          options={[{ label: 'test', value: 'test' }]}
          control={control}
          error={formState.errors.proofAddressDocumentType}
          {...register('proofAddressDocumentType', { required: true })}
          className="mb-4"
        />

        <UploadField
          label={t('fields.proofAddressDocument')}
          placeholder={t('placeholders.uploadProofAddress')}
          acceptLabel={t('placeholders.acceptedFormats')}
          acceptList="JPG, PNG, PDF"
          error={formState.errors.proofDocumentAddress}
          {...register('proofDocumentAddress')}
          icon={<IconDocument />}
          className="w-full"
        />
      </div>
      <div className="flex items-center justify-between px-8 py-3 text-xs sm:px-6">
        <ProgressBar current={step?.step} max={8} />
        <div className="flex items-center">
          <Button variant="tertiary" className="mr-4" onClick={goBack}>
            {t('buttons.back')}
          </Button>
          <Button type="submit" variant="primary">
            {t('buttons.continue')}
          </Button>
        </div>
      </div>
    </form>
  );
};
