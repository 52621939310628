import classNames from 'classnames';

export const PasswordRule = ({
  isValid,
  ruleName,
  subRuleName,
}: {
  isValid: boolean;
  ruleName: string;
  subRuleName?: string;
}) => (
  <div
    className={classNames('flex w-1/2 pb-2', {
      'text-gray-900': isValid,
    })}
  >
    <div
      className={classNames('bg-gray-300 rounded-full mr-2', {
        'bg-green-400': isValid,
      })}
      style={{ flex: '0 0 6px', height: '6px', marginTop: '6px' }}
    />
    <div>
      {ruleName}
      {subRuleName && <div className="text-gray-500">{subRuleName}</div>}
    </div>
  </div>
);
