import React, { ReactNode, useCallback, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';

import { mocStepsPage } from '../domain/kyc/flow';

export interface IStepPage {
  title: string;
  step: number;
  render: any;
  index?: number;
}

export interface KycContext {
  country?: string;
  step?: IStepPage;
  dispatch: (action: Actions) => void;
  kycData?: any;
  countryList: any[];
}

export const initialState: KycContext = {
  step: undefined,
  dispatch: (action: Actions) => {},
  kycData: {},
  countryList: [
    { label: 'Test', value: 'TEST' },
    { label: 'Test 2', value: 'TEST_2' },
  ],
};

export interface AuthProviderProps {
  children: ReactNode;
}

export const reducer = (state: typeof initialState, action: Actions): typeof initialState => {
  switch (action.type) {
    case 'SET_COUNTRY':
      return {
        ...state,
        countryList: action.countryList,
      };
    case 'SET_DATA':
      return {
        ...state,
        kycData: {
          ...state.kycData,
          ...action.data,
        },
      };
    default:
      throw new Error();
  }
};

export type Actions =
  | { type: 'SET_COUNTRY'; countryList: any[] }
  | { type: 'SET_DATA'; data: any }
  | { type: 'TEST_TETTRTTTE' };

export const KycContext = React.createContext<typeof initialState>(initialState);

export const KycProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatchAction] = useReducer(reducer, initialState);
  const location = useLocation();

  const [step, setStep] = useState<IStepPage | undefined>(undefined);

  const dispatch = useCallback(
    (action: Actions) => {
      return dispatchAction(action);
    },
    [dispatchAction]
  );

  useEffect(() => {
    setStep(undefined);
    const id = get(location.pathname.split('/'), 2);
    setStep(get(mocStepsPage, id));
  }, [location]);

  return <KycContext.Provider value={{ ...state, step: step, dispatch }}>{children}</KycContext.Provider>;
};
