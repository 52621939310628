import { FC } from 'react';

export interface LabelValueProps {
  label: string;
  value?: string;
}

export const LabelValue: FC<LabelValueProps> = ({ label, value }: LabelValueProps) => {
  return (
    <div className="mb-4 text-sm text-gray-500">
      {label}

      <div className="text-base font-semibold text-gray-700">{value || '—'}</div>
    </div>
  );
};
