import { IStep } from '../../components';

import { TermsAndConditions } from './step/TermsAndConditions';

export const mocSteps: IStep[] = [
  {
    id: '1',
    index: 1,
    title: 'Terms and conditions',
    completed: true,
  },
  {
    id: '2',
    index: 2,
    title: 'Create password',
    completed: false,
  },
  {
    id: '3',
    index: 3,
    title: 'General',
    completed: false,
  },
  {
    id: '4',
    index: 4,
    title: 'Address',
    completed: false,
    child: [
      {
        id: '4',
        parent_id: '4',
        title: 'Address of residence',
        completed: true,
      },
      {
        id: '5',
        parent_id: '4',
        title: 'Proof of address',
        completed: false,
      },
    ],
  },
  {
    id: '6',
    index: 5,
    title: 'ID Document',
    completed: false,
  },
  {
    id: '7',
    index: 6,
    title: 'Selfie',
    completed: false,
  },
  {
    id: '8',
    index: 7,
    title: 'Additional information',
    completed: false,
  },
  {
    id: '9',
    index: 8,
    title: 'Review',
    completed: false,
  },
];

export const mocStepsPage = {
  '1': {
    title: 'Terms and conditions',
    step: 1,
    render: TermsAndConditions,
    index: 1,
  },
  '2': {
    title: 'Create password',
    step: 2,
    index: 2,
  },
  '3': {
    title: 'General',
    step: 3,
    index: 3,
  },
  '4': {
    title: 'Address of residence',
    step: 4,
    index: 4,
  },
  '5': {
    title: 'Proof of address',
    step: 4,
    index: 5,
  },
  '6': {
    title: 'ID Document',
    step: 5,
    index: 6,
  },
  '7': {
    title: 'Selfie',
    step: 6,
    index: 7,
  },
  '8': {
    title: 'Additional information',
    step: 7,
    index: 8,
  },
  '9': {
    title: 'Review',
    step: 8,
    index: 9,
  },
};
