import React from 'react';

import { ReactComponent as IconLogo } from '../../assets/icons/icon-logo.svg';

export const Topbar = () => {
  return (
    <div className="z-20 w-full h-16 bg-white border-b">
      <div className="flex items-center justify-between h-16 px-16 mx-auto max-w-8xl">
        <IconLogo />
      </div>
    </div>
  );
};
