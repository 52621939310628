import { Button, SelectField, TextField, CameraPreview } from '@agilepartners/fe-sdk-uikit';
import { ProgressBar } from 'components';
import useKyc from 'hooks/useKyc';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const IdDocument = () => {
  const { t } = useTranslation();
  const { step, countryList } = useKyc();
  const { push } = useHistory();

  const schema = useMemo(() => {
    return yup.object().shape({
      nationality: yup.string().required(t('validations.errors.required')),
      documentType: yup.string().required(t('validations.errors.required')),
      documentNumber: yup.string().required(t('validations.errors.required')),
    });
  }, [t]);

  const { control, register, handleSubmit, formState, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onFormSubmit = () => {
    return;
  };

  const goBack = () => {
    push(`/step/${step?.index! - 1}`);
  };

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="shadow auth-w-full auth-p-10 lg:auth-p-24 auth-bg-white auth-rounded"
    >
      <div className="px-6 py-5 border-t-2 border-b-2 border-gray-200">
        <SelectField
          label={t('fields.nationality')}
          placeholder={t('fields.nationality')}
          options={countryList}
          control={control}
          error={formState.errors.nationality}
          {...register('nationality', { required: true })}
          className="mb-4"
        />

        <SelectField
          label={t('fields.documentType')}
          placeholder={t('fields.documentType')}
          options={countryList}
          control={control}
          error={formState.errors.documentType}
          {...register('documentType', { required: true })}
          className="mb-4"
        />

        <TextField
          label={t('fields.documentNumber')}
          placeholder={t('fields.documentNumber')}
          type="text"
          error={formState.errors.documentNumber}
          {...register('documentNumber', { required: true })}
          className="w-full mr-4"
        />

        <CameraPreview control={control} label={'Selfie'} />
      </div>

      <div className="flex items-center justify-between px-8 py-3 text-xs sm:px-6">
        <ProgressBar current={step?.step} max={8} />

        <div className="flex items-center">
          <Button variant="tertiary" className="mr-4" onClick={goBack}>
            {t('buttons.back')}
          </Button>
          <Button type="submit" variant="primary">
            {t('buttons.continue')}
          </Button>
        </div>
      </div>
    </form>
  );
};
