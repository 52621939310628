import { ProgressBar } from 'components';
import { Button } from '@agilepartners/fe-sdk-uikit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useKyc from 'hooks/useKyc';

export const TermsAndConditions = () => {
  const { step } = useKyc();
  const { t } = useTranslation();
  const { push } = useHistory();

  const onAccept = () => {
    push(`/step/${step?.index! + 1}`);
  };

  return (
    <div className="shadow auth-w-full auth-p-10 lg:auth-p-24 auth-bg-white auth-rounded">
      <div className="px-6 py-5 border-t-2 border-b-2 border-gray-200">Introduction</div>
      <div className="flex items-center justify-between px-8 py-3 text-xs sm:px-6">
        <ProgressBar current={step?.step} max={8} />

        <Button onClick={onAccept} variant="primary">
          {t('buttons.tcAccept')}
        </Button>
      </div>
    </div>
  );
};
