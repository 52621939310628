import React from 'react';

import { ReactComponent as IconLogoSmallGray } from '../../assets/icons/icon-logo-small-gray.svg';

export const Footer = React.forwardRef<HTMLDivElement>(({ children, ...props }, ref) => {
  return (
    <div ref={ref} {...props}>
      <div className="flex items-center justify-between h-16 px-16 mx-auto text-sm text-black max-w-8xl">
        <div className="flex items-center gap-12">
          <div>
            <IconLogoSmallGray />
          </div>
          <a href="/" target="_blank" rel="noreferrer" className="text-sm text-gray-600">
            Help
          </a>
          <a href="/" target="_blank" rel="noreferrer" className="text-sm text-gray-600">
            Legal
          </a>
        </div>
        <div>
          <span className="text-xs text-gray-500">
            ©{new Date().getFullYear()} Payall Payment Systems. All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  );
});
