import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { KycWrapper } from '../domain/kyc/KycWrapper';
import { LayoutApp } from '../layouts/app/LayoutApp';

export const PrivateRoute = (props: RouteProps): JSX.Element => {
  // const [translationsLoaded, setTranslationsLoaded] = useState(false);
  //
  // useEffect(() => {
  //   const loadResource = async () => {
  //     i18n.addResourceBundle('en', 'translation', await import('../locales/en/translationAuth.json'), true, true);
  //
  //     setTranslationsLoaded(true);
  //   };
  //
  //   loadResource();
  // }, []);
  //
  // if (!translationsLoaded) {
  //   return (
  //     <div className="flex items-center justify-center w-full h-screen">
  //       <Loading />
  //     </div>
  //   );
  // }

  return (
    <div className="w-full h-full">
      <LayoutApp>
        <Route path="/step">
          <KycWrapper />
        </Route>
      </LayoutApp>
    </div>
  );
};
