import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { PrivateRoute } from 'auth/PrivateRoute';

import { KycProvider } from './KycContext';

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path="/" />
    </Switch>
  );
};

export const App = () => {
  return (
    <Router>
      <KycProvider>
        <Routes />
      </KycProvider>
    </Router>
  );
};
