import { Button, SelectField, TextField } from '@agilepartners/fe-sdk-uikit';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { ProgressBar } from 'components';
import useKyc from 'hooks/useKyc';

export const AddressOfResidence = () => {
  const { t } = useTranslation();
  const { step, dispatch, kycData, countryList } = useKyc();
  const { push } = useHistory();

  const schema = useMemo(() => {
    return yup.object().shape({
      countryResidence: yup.string(),
      city: yup.string(),
      postalCode: yup.string().required(t('validations.errors.required')),
      suburb: yup.string(),
      country: yup.string(),
      state: yup.string().required(t('validations.errors.required')),
      street: yup.string().required(t('validations.errors.required')),
      streetType: yup.string(),
      buildingNumber: yup.string(),
      unitNumber: yup.string(),
    });
  }, [t]);

  const { register, handleSubmit, formState, control, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: kycData,
  });

  const onFormSubmit = (data: any) => {
    dispatch({ type: 'SET_DATA', data });
    push(`/step/${step?.index! + 1}`);
  };

  const goBack = () => {
    push(`/step/${step?.index! - 1}`);
  };

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="shadow auth-w-full auth-p-10 lg:auth-p-24 auth-bg-white auth-rounded"
    >
      <div className="px-6 py-5 border-t-2 border-b-2 border-gray-200">
        <div className="mb-6">
          <SelectField
            disabled={true}
            className="mb-2"
            label={t('fields.countryResidence')}
            placeholder={t('fields.countryResidence')}
            options={countryList}
            control={control}
            error={formState.errors.countryResidence}
            {...register('countryResidence', { required: true })}
          />
          <div className="text-xs text-gray-500">{t('helpers.onEdit')}</div>
        </div>

        <div className="flex mb-4">
          <TextField
            label={t('fields.city')}
            placeholder={t('fields.city')}
            type="text"
            topHelper={t('helpers.optional')}
            error={formState.errors.city}
            {...register('city', { required: true })}
            className="w-full mr-4"
          />

          <TextField
            label={t('fields.postalCode')}
            placeholder={t('fields.postalCode')}
            type="text"
            error={formState.errors.postalCode}
            {...register('postalCode')}
            className="w-64"
          />
        </div>

        <TextField
          label={t('fields.suburb')}
          placeholder={t('fields.suburb')}
          type="text"
          topHelper={t('helpers.optional')}
          error={formState.errors.suburb}
          {...register('suburb')}
          className="mb-4"
        />

        <TextField
          label={t('fields.country')}
          placeholder={t('fields.country')}
          type="text"
          topHelper={t('helpers.optional')}
          error={formState.errors.country}
          {...register('country')}
          className="mb-4"
        />

        <SelectField
          label={t('fields.state')}
          placeholder={t('fields.state')}
          options={[{ label: 'test', value: 'test' }]}
          control={control}
          error={formState.errors.state}
          {...register('state', { required: true })}
          className="mb-4"
        />

        <TextField
          label={t('fields.street')}
          placeholder={t('fields.street')}
          type="text"
          error={formState.errors.street}
          {...register('street', { required: true })}
          className="mb-4"
        />

        <SelectField
          label={t('fields.streetType')}
          placeholder={t('fields.streetType')}
          options={[]}
          control={control}
          error={formState.errors.streetType}
          topHelper={t('helpers.optional')}
          {...register('streetType', { required: true })}
          className="mb-4"
        />

        <div className="flex">
          <TextField
            label={t('fields.buildingNumber')}
            placeholder={t('fields.buildingNumber')}
            type="text"
            error={formState.errors.buildingNumber}
            topHelper={t('helpers.optional')}
            {...register('buildingNumber', { required: true })}
            className="w-full mr-4"
          />
          <TextField
            label={t('fields.unitNumber')}
            placeholder={t('fields.unitNumber')}
            type="text"
            error={formState.errors.unitNumber}
            topHelper={t('helpers.optional')}
            {...register('unitNumber', { required: true })}
            className="w-full"
          />
        </div>
      </div>

      <div className="flex items-center justify-between px-4 py-5 text-xs sm:px-6">
        <ProgressBar current={step?.step} max={8} />
        <div className="flex items-center">
          <Button variant="tertiary" className="mr-4" onClick={goBack}>
            {t('buttons.back')}
          </Button>
          <Button type="submit" variant="primary">
            {t('buttons.continue')}
          </Button>
        </div>
      </div>
    </form>
  );
};
