import React, { FC } from 'react';
import classNames from 'classnames';

import { StepProps } from './Step';

export const SubStep: FC<StepProps> = (props: StepProps) => {
  const { step, active } = props;

  const isActive = step.id === active;
  const isCompleted = step.completed;

  return (
    <>
      <div
        className={classNames('py-5 px-6 flex items-center cursor-pointer font-sans', {
          'border-l-4 border-blue-200': isActive,
          'border-l-4 border-green-200': isCompleted,
        })}
      >
        <span className={classNames('text-gray-700 text-xs', { 'text-gray-900 font-semibold': isActive })}>
          {step.title}
        </span>
      </div>
    </>
  );
};
